<template>
  <div class="home">
    <PageTitle icon="" main="" sub="" v-if="uMsgStatusActive"/>
    <CadasPanels v-if="uMsgStatusActive && userParams.tipoUsuario == '0'" icon="fa fa-home" main="Dashboard" :sub="subTitle" />
    <div class="float-right" v-if="uMsgStatusActive && userParams.tipoUsuario >= '1' || userParams.admin >= '1'">
    </div>
    <div v-if="userMsg">
      <b-alert show :variant="msg.msg_variant || 'success'" v-for="msg in userMsg" :key="msg.id">
        <h4 class="alert-heading" v-html="msg.titl"></h4>
        <span v-html="msg.info"></span>
      </b-alert>
      <b-alert class="text-center" v-if="!(userParams.id_emp || (userParams.cliente && userParams.dominio))" show variant="warning">Olá, {{ userParams.name }}! Estamos quase lá!<br />Mas antes de prosseguir, por favor entre em contato com o <a href="mailto:nao-responda@mgcash.app.br">suporte</a> para solicitar a liberação de seu acesso aos dados.<br/>Informe email e CPF para localizar seu perfil, ok? <span style='font-size:26px;'>&#128521;</span></b-alert>
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import CadasPanels from "../painelConsignacoes/CadasPanels";
import { appName } from "@/global";
import { mapState } from "vuex";
import { baseApiUrl } from "@/env";
import axios from "axios";
import { showError } from '../../global'
import moment from 'moment'

export default {
  name: "Home",
  components: { PageTitle, CadasPanels },
  data: function () {
    return {
      userParams: {},
      userMsg: [],
      uMsgStatusActive: true,
      title: appName,
      subTitle: `Consignados e Pagamentos Online`,
    };
  },
  methods: {
    loadUserMsgS() {
      const url = `${baseApiUrl}/users/f-a/gss`;
      axios.get(url).then((res) => {
        this.uMsgStatusActive = res.data;
      })
        .catch(showError)
    },
    loadUserMsg() {
      const url = `${baseApiUrl}/users/f-a/gsm`;
      axios.get(url).then((res) => {
        this.userMsg = res.data;
        this.userMsg.forEach(element => {
          if (element.valid_from > moment().format()) {
            element.info = element.msg
            element.titl = element.title
          }
          else if (element.valid_from <= moment().format()) {
            element.info = element.msg_future
            element.titl = element.title_future
          }
        });
      })
        .catch(showError)
    },
    async loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      await axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
  },
  mounted() {
    setTimeout(async () => {
      await this.loadUserParams();
      await this.loadUserMsg()
      await this.loadUserMsgS()
    }, Math.random() * 1000);
  },
  computed: mapState(["user"]),
};
</script>
